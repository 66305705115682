/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'

import { Forms, Intro, Contacts, WhatMatters, Results } from '../components/Contact'

const contact = props => {
  return (
    <Layout>
      <Intro />
      <Forms 
        location={props.location.pathname}
      />
      <WhatMatters />
      <Results />
      <Contacts/>
    </Layout>
  )
}

export default contact


