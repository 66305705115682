/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import './styled.css'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

const Results = () => {
  const ResultsTopImages = useStaticQuery(graphql`
      query ResultsTopImages {
        contact1: file(absolutePath: {regex: "/assets/images/contact-image-01.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 724) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        contact2: file(absolutePath: {regex: "/assets/images/contact-image-02.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1000) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        contact3: file(absolutePath: {regex: "/assets/images/contact-image-03.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 788) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        contact4: file(absolutePath: {regex: "/assets/images/contact-image-04.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 960) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        contact5: file(absolutePath: {regex: "/assets/images/contact-image-05.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1000) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <div className="blackBg spb-10">
        <Container>
          <div className="col-12 offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6 text-center sp-5">
            <h2>Small teams. Big results</h2>
            <p>
              We work in small, dedicated teams of product managers, designers
              and developers. Each team works on one project at a time to stay
              focused. We use tools like Notion, Slack and GitHub to communicate
              frequently.
            </p>
          </div>
          <Row>
            <Col sm="12 smallteam-grid">
            	<div>
            		<Image
                  sizes={ResultsTopImages.contact1.childImageSharp.sizes}
                />
            	</div>
            	<div>
            		<Image
                  sizes={ResultsTopImages.contact2.childImageSharp.sizes}
                />
		         </div>
		         <div>
		         	<Image
                sizes={ResultsTopImages.contact3.childImageSharp.sizes}
              />
		         </div>
		         <div>
		         	<Image
                sizes={ResultsTopImages.contact4.childImageSharp.sizes}
              />
		         </div>
		         <div>
              <Image
                sizes={ResultsTopImages.contact5.childImageSharp.sizes}
              />
		         </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
export default Results
