/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import './styled.css'

class IntroContact extends React.Component {
  render() {
    return (
      <Container className="contactSection content-wrap">
        <Row className="sp-3">
          <Col xl="6" lg="7" md="8" className="offset-xl-3 offset-lg-1">
            <h1>Let's find out if we're the right digital partner for you</h1>
            <p>Or if you don't like forms get in touch by sending us an
              email to{' '}
              <a href="mailto:newbiz@urbian.io" className="underline">
                newbiz@urbian.io
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default IntroContact
